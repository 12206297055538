import * as React from "react"
import { Helmet } from "react-helmet";

// import '/src/keygen.js';
// import keygen from './../keygen.js';

import ogImage from "./../images/og-image.jpg";

import Navbar from './../sections/navbar.js';
import Footer from './../sections/footer.js';

class CheckoutPage extends React.Component {

    render() {
        return (
            <div id="overview">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bergify - Wordpress Theme</title>
                    <link rel="canonical" href="https://www.bergify.com/checkout" />
                    <meta name="description" content="Bergify is a block-based Wordpress theme suitable for businesses." />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@bergify_" />
                    <meta name="twitter:title" content="Bergify PRO - Premium Wordpress Theme" />
                    <meta name="twitter:description" content="Take your Wordpress website to the next level with Bergify PRO." />
                    <meta name="twitter:image" content={'https://www.bergify.com' + ogImage} />
                    <meta name="twitter:creator" content="@bergify_" />

                    <meta property="og:url" content="https://www.bergify.com/" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Bergify PRO - Premium Wordpress Theme" />
                    <meta property="og:description" content="Take your Wordpress website to the next level with Bergify PRO." />
                    <meta property="og:image" content={'https://www.bergify.com' + ogImage} />
                </Helmet>
                <Navbar></Navbar>
                <main>
                    <section className="bg-gray-50 py-16 lg:py-24" id="pricing">
                        <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                            <div className="xl:mx-64 2xl:mx-80">

                                <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-6">Thank you for subscribing!</h1>
                                <p className="text-gray-600 text-lg lg:text-lg mb-10">Your subscription has been successfully confirmed. You now have access to Bergify PRO. All the details have been sent to your email address.</p>
                                <p>If you have any questions or need assistance, please contact us at <a href="mailto:info@bergify.com" className="text-blue-600 hover:underline" target="_blank">info@bergify.com</a>.</p>
                                <p>Visit our <a href="https://www.bergify.com" className="text-blue-600 hover:underline">homepage</a> to explore more.</p>
                                </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </main>
            </div>
        )
    }

}
export default CheckoutPage
